<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/mankyyhq/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <!--<div><el-input v-model="where.keyword" placeholder="请输入姓名或手机号码"></el-input></div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>-->
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="id" label="#"  ></el-table-column>

                    <!--<el-table-column label="性别" width="50px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_XINGB==1">男</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_XINGB==2">女</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_SHOUJIHM" label="手机号码" width="100px"></el-table-column>
                    <el-table-column label="头像" width="70px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                            </dl>
                        </template>
                    </el-table-column>

                    <el-table-column label="微信二维码" width="80px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_WEIXEWM||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                            </dl>
                        </template>
                    </el-table-column>-->

                    <el-table-column prop="mands" label="满多少可用" ></el-table-column>
                    <el-table-column prop="dik" label="抵扣金额"></el-table-column>
                    <!--<el-table-column prop="leix" label="类型"></el-table-column>-->
                    <el-table-column prop="youxts" label="领取后有效天数"></el-table-column>

                    <el-table-column prop="adv_start" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.createtime<=0"> - </div>
                            <div v-else>{{scope.row.createtime|formatDate}}</div>
                        </template>
                    </el-table-column>


                    <el-table-column label="操作" fixed="right" >
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'mankyyhq_form',params:{id:scope.row.id}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list:[],
                total_data:0, // 总条数
                page_size:20,
                current_page:1,
                select_id:'',
                adv_position_id:0,
                where:{
                },
                info:[],
            };
        },
        watch: {},
        computed: {},
        methods: {
            handleSelectionChange:function(e){
                let ids = [];
                e.forEach(v => {
                    ids.push(v.id);
                });
                this.select_id = ids.join(',');
            },
            get_adv_list:function(){
                this.where.page = this.current_page;
                this.$get(this.$api.getMankyyhqList,this.where).then(res=>{
                    this.page_size = res.data.per_page;
                    this.total_data = res.data.total;
                    this.current_page = res.data.current_page;
                    this.list = res.data.data;
                })

            },
            // 删除处理
            del:function(id){
                if(this.$isEmpty(id)){
                    return this.$message.error('请先选择删除的对象');
                }
                this.$post(this.$api.delMankyyhq,{id:id}).then(res=>{
                    if(res.code == 200){
                        this.get_adv_list();
                        return this.$message.success("删除成功");
                    }else{
                        return this.$message.error("删除失败");
                    }
                });
            },
            current_change:function(e){
                this.current_page = e;
                this.get_adv_list();
            },
        },
        created() {
            this.get_adv_list();
        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>
</style>